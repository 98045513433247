import React, { useEffect, useState, useMemo } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import BlockContainer from "../Core/Container/BlockContainer"
import ContentContainer from "../Core/Container/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import breakpoints from "../../styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import BrandPattern from "../../resources/img/patterns/brand-pattern-04.svg"

const StyledContainer = styled.div`
  background: ${colors.ivory};

  @media (min-width: ${breakpoints.xl}) {
    padding: 0 62px;
  }
`

const TitleBlock = styled.div`
  @media (min-width: ${breakpoints.md}) {
    margin-top: 133px;
    text-align: center;
  }
`

const Title = styled.h1`
  ${fonts.canelaThin};
  font-size: 40px;
  line-height: 40px;
  @media (min-width: ${breakpoints.md}) {
    font-size: 72px;
    line-height: 72px;
    max-width: 1127px;
    margin: auto;
  }
`

const SubHeader = styled.div`
  ${fonts.labGrotesqueLight};
  margin-top: 28px;
  font-size: 18px;
  line-height: 28px;

  a {
    color: ${colors.grey};
  }

  b {
    ${fonts.labGrotesqueBold};
  }

  i {
    font-style: italic;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 540px;
    margin: 18px auto 0;
  }
`

const PointsBlock = styled.div`
  padding-bottom: 64px;
  position: relative;
`

const Point = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 12px;
  line-height: normal;
  letter-spacing: 2.4px;
  position: absolute;
  left: 50%;
  bottom: 167px;
  transform: translateX(-50%);
  text-align: center;
  text-transform: uppercase;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: all 3s;
  width: 100%;
  padding: 0 48px;
  z-index: 1;

  @media (min-width: ${breakpoints.md}) {
    top: 50%;
    bottom: unset;
    transform: translate(0, -50%);
    left: calc(25% - 125px);
    padding: 0;
    max-width: 250px;
  }
  @media (min-width: ${breakpoints.lg}) {
    left: calc(50% - 250px);
  }
  @media (min-width: ${breakpoints.xl}) {
    transform: translate(-50%, -50%);
    left: 50%;
    max-width: 300px;
  }
  @media (min-width: ${breakpoints.xxl}) {
    max-width: 400px;
  }
`

const HourHand = styled.div`
  background: ${colors.grey};
  width: 1px;
  height: 31px;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: ${breakpoints.md}) {
    width: 31px;
    height: 1px;
    bottom: unset;
    left: 0;
    top: 50%;
    transform: translateX(-50%);
  }
`

const TextBlock = styled.div`
  margin: 98px 0 60px;

  h2 {
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 100%;
    margin-bottom: 28px;
  }

  p {
    font-size: 18px;
    line-height: 180%;
    margin-bottom: 28px;
    :last-of-type {
      margin-bottom: 0;
    }

    b {
      ${fonts.labGrotesqueBold};
    }
  }

  ul {
    margin: 28px 0;
    list-style: disc;
    margin-left: 25px;

    p {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    margin: 175px 0 3px;
  }
`

const ClockContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 281px;
  @media (min-width: ${breakpoints.md}) {
    min-height: unset;
  }
`

const Clock = styled.div`
  width: 866px;
  height: 866px;
  border: 1px solid ${colors.orangeDark};
  border-radius: 50%;
  margin-left: calc(50% - 433px);
  margin-top: -585px;
  position: absolute;

  @media (min-width: ${breakpoints.md}) {
    /* width: 956px;
    height: 956px; */
    margin-top: -30px;
    margin-left: 0;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-left: 20%;
  }
`

const HoursContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  transform: ${(props) => `rotate(${props.activeHour * -15 + 180}deg)`};
  transition: all 6s ease-in;

  @media (min-width: ${breakpoints.md}) {
    transform: ${(props) => `rotate(${props.activeHour * -15 + -90}deg)`};
  }
`

const Hour = styled.div`
  ${fonts.labGrotesqueBold};
  letter-spacing: 3px;
  font-size: 12px;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 50px;
  transform: ${(props) => `rotate(${props.index * 15}deg)`};
  color: ${(props) => (props.active ? colors.grey : colors.orangeDark)};
  transition: all 6s ease-in;

  @media (min-width: ${breakpoints.md}) {
    left: unset;
  }
`

const StyledCol = styled(Col)`
  position: relative;
`

const Pattern = styled.div`
  position: absolute;
  right: 40%;
  top: -10%;
  opacity: 0.6;
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
  @media (min-width: ${breakpoints.lg}) {
    right: 20%;
    display: block;
  }
  @media (min-width: ${breakpoints.xxl}) {
    right: 0;
    display: block;
  }
`

const IntroBlock = (props) => {
  const { header, subHeader, references } = props

  const [active, setActive] = useState(0)
  const [activeHour, setActiveHour] = useState(0)

  let blocks = []
  let hours = []

  for (let i = 0; i < 24; i++) {
    hours.push(
      <Hour key={i} index={i} active={activeHour === i ? 1 : 0}>
        {i + 1}
      </Hour>
    )
  }

  const points = useMemo(() => {
    const pointsArray = []
    for (const reference of references) {
      if (reference.__typename === "ContentfulPoint") {
        pointsArray.push(reference)
      }
    }
    return pointsArray
  }, [references])

  useEffect(() => {
    const cyclePoints = () => {
      setActive((prevActive) => (prevActive + 1) % points.length)
      setActiveHour((prevActiveHour) => (prevActiveHour + 1) % 24)
    }
    const interval = setInterval(cyclePoints, 6000)
    return () => clearInterval(interval)
  }, [points.length, activeHour])

  const renderBlocks = () => {
    references.forEach((reference, index) => {
      if (reference.__typename === "ContentfulTextBlock") {
        blocks.push(
          <StyledCol md={6} lg={5}>
            <TextBlock>
              <ContentContainer>
                {renderRichText(reference.content)}
              </ContentContainer>
            </TextBlock>
          </StyledCol>
        )
      }
      if (reference.__typename === "ContentfulPoint") {
        points.push(reference)
      }
      if (
        points.length !== 0 &&
        index ===
          references.map((el) => el.__typename).lastIndexOf("ContentfulPoint")
      ) {
        blocks.push(
          <StyledCol md={6} lg={7} order={"first"} mdOrder={2}>
            <PointsBlock>
              <ClockContainer>
                <Clock>
                  <HourHand />
                  <HoursContainer activeHour={activeHour.toString()}>
                    {hours}
                  </HoursContainer>
                  {points.map((point, index) => {
                    return (
                      <Point key={index} visible={index === active ? 1 : 0}>
                        {point.point}
                      </Point>
                    )
                  })}
                  <Pattern>
                    <img src={BrandPattern} alt={process.env.WEBSITE_TITLE} />
                  </Pattern>
                </Clock>
              </ClockContainer>
            </PointsBlock>
          </StyledCol>
        )
      }
    })
    return <>{blocks}</>
  }

  return (
    <BlockContainer>
      <StyledContainer>
        <Row>
          <StyledCol mdOrder={0}>
            <TitleBlock>
              <ContentContainer>
                <Title>{header}</Title>
                {subHeader && (
                  <SubHeader>{renderRichText(subHeader)}</SubHeader>
                )}
              </ContentContainer>
            </TitleBlock>
          </StyledCol>
          {renderBlocks()}
        </Row>
      </StyledContainer>
    </BlockContainer>
  )
}

export default IntroBlock
