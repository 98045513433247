import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContent from "../components/About/PageContent"

const AboutPage = ({ location, data }) => {
  const path = location.pathname
  const { page } = data
  return (
    <Layout>
      <Seo
        path={path}
        title={page.metaTitle}
        description={page.metaDescription}
      />
      <PageContent blocks={page.blocks} />
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
    page: contentfulPage(slug: { eq: "about" }) {
      id
      metaTitle
      metaDescription
      blocks {
        __typename
        ... on ContentfulIntroBlock {
          id
          header
          subHeader {
            raw
          }
          references {
            __typename
            ... on ContentfulPoint {
              id
              point
            }
            ... on ContentfulTextBlock {
              id
              content {
                raw
              }
            }
          }
        }
        ... on ContentfulTextBlock {
          id
          content {
            raw
          }
        }
        ... on ContentfulTextImageBlock {
          id
          image {
            title
            gatsbyImageData(width: 1356)
          }
          content {
            raw
          }
          imagePosition
        }
        ...expertCarouselFragment
        ...uspBlockFragment
      }
    }
  }
`
