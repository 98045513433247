import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import BlockContainer from "../Core/Container/BlockContainer"
import ContentContainer from "../Core/Container/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Half from "../../resources/img/core/background/half.png"
import breakpoints from "../../styles/breakpoints"

const StyledContainer = styled.div`
  background: ${colors.ivory};
  padding-top: 38px;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 107px;
  }
`

const Circle = styled.div`
  background-image: url(${Half});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  min-height: 50vw;
  margin-top: -50vw;

  @media (min-width: ${breakpoints.xxl}) {
    background-size: 100% 800px;
  }
`

const Contents = styled.div`
  text-align: center;
  margin-bottom: 133px;
  h2 {
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 33px;
  }

  h3 {
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 40px;
    padding: 0 20px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 83px;
    b {
      ${fonts.labGrotesqueBold};
    }
    :last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 108px;
    h2 {
      line-height: 52px;
    }
  }
`

const TextBlock = (props) => {
  const { content } = props
  return (
    <React.Fragment>
      <BlockContainer>
        <StyledContainer>
          <ContentContainer>
            <Contents>{renderRichText(content)}</Contents>
          </ContentContainer>
        </StyledContainer>
      </BlockContainer>
      <Circle />
    </React.Fragment>
  )
}

export default TextBlock
