import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import BlockContainer from "../Core/Container/BlockContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Col, Row } from "styled-bootstrap-grid"

const TextImageBlockContainer = styled.div`
  padding: 38px 28px;
  background: ${colors.ivory};
  @media (min-width: ${breakpoints.lg}) {
    padding: 43px 42px;
  }
`

const StyledImage = styled(GatsbyImage)`
  border-radius: 15px;
  max-width: 500px;
  margin: auto;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 678px;
    min-height: 100%;
    ${(props) => (props.left ? "margin-right: auto" : "margin-left: auto")};
  }
`

const Content = styled.div`
  text-align: center;
  h2 {
    ${fonts.canelaThin};
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 18px;
  }

  a {
    color: inherit;
  }

  p {
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 28px;
    :last-of-type {
      margin-bottom: 0;
    }

    b {
      ${fonts.labGrotesqueBold};
    }

    i {
      font-style: italic;
    }
  }

  blockquote {
    p {
      ${fonts.canelaThin};
      font-size: 24px;
      line-height: 31px;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
    h2 {
      margin-bottom: 25px;
    }
    blockquote {
      p {
        line-height: 43px;
      }
    }
  }
`

const StyledCol = styled(Col)`
  margin-top: 63px;
  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
    margin-top: 0;
    ${(props) => (props.right ? "padding-left: 99px" : "padding-right: 99px")};
    ${(props) => (props.right ? "padding-right: 58px" : "padding-left: 66px")};
  }
`

const TextImageBlock = (props) => {
  const { imagePosition, image, content } = props
  return (
    <BlockContainer>
      <TextImageBlockContainer>
        <Row>
          <Col lg={6}>
            <StyledImage
              image={image.gatsbyImageData}
              alt={image.title}
              left={imagePosition ? 1 : 0}
            />
          </Col>
          <StyledCol
            lg={6}
            lgOrder={imagePosition ? "last" : "first"}
            right={imagePosition ? 1 : 0}
            lgAlignSelf="center"
          >
            <Content>{renderRichText(content)}</Content>
          </StyledCol>
        </Row>
      </TextImageBlockContainer>
    </BlockContainer>
  )
}

export default TextImageBlock
