import React from "react"
import UspBlock from "../Core/USP/UspBlock"
import IntroBlock from "./IntroBlock"
import TextImageBlock from "./TextImageBlock"
import TextBlock from "./TextBlock"
import Experts from "../Core/Experts"
import styled from "styled-components"

const Container = styled.div`
  overflow: hidden;
`

const PageContent = ({ blocks }) => {
  let pageContents = []

  blocks.forEach((block, index) => {
    const type = block.__typename
    // console.log(block)

    if (type === "ContentfulIntroBlock") {
      pageContents.push(<IntroBlock {...block} key={index} />)
    }
    if (type === "ContentfulTextImageBlock") {
      pageContents.push(<TextImageBlock {...block} key={index} />)
    }
    if (type === "ContentfulTextBlock") {
      pageContents.push(<TextBlock {...block} key={index} />)
    }
    if (type === "ContentfulExpertCarousel") {
      pageContents.push(<Experts {...block} key={index} />)
    }

    if (type === "ContentfulUspBlock") {
      pageContents.push(<UspBlock {...block} key={index} />)
    }
  })

  return <Container>{pageContents}</Container>
}

export default PageContent
